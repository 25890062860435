import React, { useState } from "react";
import {config} from "../../_helpers"
import html2pdf from 'html2pdf.js';
import Currency from "react-currency-formatter";
import "./Invoice.css";

const Invoice = ({ user, userDetails, loggedInUser }) => {
  const [logo, setLogo] = useState(null);
  let imgLogo = config && config.Black_Theme_Logo
  // Function to handle PDF generation
  const handleDownloadPdf = () => {
    const element = document.getElementById("invoice");
    const opt = {
      margin: 1,
      filename: 'myDocument.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().from(element).set(opt).save();
  };

  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  toDataURL(imgLogo, function (dataUrl) {
    console.log('RESULT:', dataUrl)
    setLogo(dataUrl);
  })

  function convertDateFormate(dateString) {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const dateNumber = date.getDate();
    const year = date.getFullYear();
    const formattedDate = `${month}/${dateNumber}/${year}`;
    return formattedDate;
  }
  

  let userPayments = user && JSON.parse(user.purchaseItems)[0];
 
  return (
    <div className="invoice_main"  >
      <div className="main_container">

        <div className="sub_container">

          <div className="closeContainer">
            <button className="printBtn" onClick={handleDownloadPdf}>Download</button>
          </div>

          <div className="invoice" id="invoice">
            <div className="invoice_container">
              <div className="invoice_header">
                <div className="header1" >
                  <img src={logo !== null && logo} height={"40px"} />
                  <h6>{config && config.Provider}</h6>
                  <span>{loggedInUser.phone}</span>
                  <span>{loggedInUser.email}</span>
                  <span>{loggedInUser.address}</span>
                </div>
                <div className="header2" >
                {user&&user.payStatus&&user.payStatus.toLowerCase()==="paid"?"": <h6 style={{marginRight:"9px"}}>Pro-forma</h6>}
                  <h1>Invoice</h1>
                  <h5>#{user && user.invoicePath}</h5>
                  {user&&user.payStatus&&user.payStatus.toLowerCase()==="paid"? <span className="chip">Paid</span>:""}
                </div>
              </div>

              <div className="adress_Container">
                <h4>Billing To:</h4>
                <div className="adress">
                  <div className="adress1" >
                    <span><b>Name:</b>{userDetails.firstName}</span>
                    <span><b>Address:</b>{userDetails.address}</span>
                    {/* <span><b>Payment Method:</b>Card</span> */}
                  </div>
                  <div className="adress2" >
                    <span><b>Phone:</b>{userDetails.phone}</span>
                    <span><b>Email:</b>{userDetails.email}</span>
                    <span><b>Date:</b>{user&&user.payStatus&&user.payStatus.toLowerCase()==="paid"?convertDateFormate(user.updatedAt):convertDateFormate(user.createdAt)}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="invoice_container2">
              <div className="invoic_data">
                <h4>Bill Summary</h4>
                <p>
                  {userPayments.price_data.product_data.name} &nbsp;,
                  {userPayments.price_data.product_data.description}&nbsp;, 
                
                  <Currency
                    quantity={
                      userPayments.price_data.unit_amount / 100
                    }
                    currency={
                      userPayments.price_data
                        .currency === "INR" ? "INR" : userPayments.price_data.currency} />&nbsp;,
                  {userPayments.quantity}
                </p>
              </div>

              <div className="totalContainer">
                <div>
                  <h6>SUBTOTAL:</h6>
                  <h6>
                    <Currency
                      quantity={
                        userPayments.price_data.unit_amount / 100

                      }
                      currency={
                        userPayments.price_data
                          .currency === "INR" ? "INR" : userPayments.price_data.currency} />
                  </h6>
                </div>
                <div>
                  <h6>TAX:</h6>
                  <h6>
                    <Currency
                      quantity={
                        ((userPayments.price_data.unit_amount / 100) * loggedInUser.taxPerc / 100)

                      }
                      currency={
                        userPayments.price_data
                          .currency === "INR" ? "INR" : userPayments.price_data.currency} /></h6>
                </div>
                <div>
                  <h6 className="total">TOTAL:</h6>
                  <h6  className="total">
                    <Currency
                      quantity={
                        parseInt((userPayments.price_data.unit_amount/100) + ((userPayments.price_data.unit_amount / 100) * loggedInUser.taxPerc / 100))
                      }
                      currency={
                        userPayments.price_data
                          .currency === "INR" ? "INR" : userPayments.price_data.currency}
                    /></h6>
                </div>

              </div>


              <div style={{borderBottom:"2px black solid"}}>
                <p><b>Notes:</b>Thanks for you biz with us.</p>

                <h5>Terms & Conditions</h5>
                <p>
                Kindly pay within 15 days.
                </p>
              </div>

            </div>
          </div>


        </div>


      </div>
    </div>
  );
};



export default Invoice;
