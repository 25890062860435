import { BehaviorSubject } from 'rxjs';

import { fetchWrapper, history, config, util } from './../_helpers';
const userSubject = new BehaviorSubject(null);

//const baseUrl='https://authapi.digitallive24.com' ;

const accountService = {
    login,
    logout,
    refreshToken,
    register,
    verifyEmail,
    forgotPassword,
    validateResetToken,
    resetPassword,
    getAll,
    getById,
    create,
    roles,
    getRoles,
    update,
    passwrd,
    getMember,
    delete: _delete,
    user: userSubject.asObservable(),
    get userValue () { return userSubject.value }
};

export default accountService;

function roles(roleInfo){
    const baseUrl = config && config.AUTH_API_SERVER;
    return fetchWrapper.post(`${baseUrl}/role`, { ...roleInfo })
        .then(user => {
            // userSubject.next(user);
            return user;
        });
}

function getRoles(){
    const baseUrl = config && config.AUTH_API_SERVER;
    return fetchWrapper.get(`${baseUrl}/role`)
        .then(user => {
            // userSubject.next(user);
            //startRefreshTokenTimer();
            return user;
        });
}

function getMember(urlUsr){
    const baseUrl = config && config.AUTH_API_SERVER;
    return fetchWrapper.get(`${baseUrl}/user/members?userid=`+urlUsr)
        .then(user => {
            // publish user to subscribers and start timer to refresh token
            // userSubject.next(user);
            //startRefreshTokenTimer();
            return user;
        });
}


function login(email, password) {
    const baseUrl = config && config.AUTH_API_SERVER;
    return fetchWrapper.post(`${baseUrl}/user/authenticate`, { email, password })
    // return  fetchWrapper.post(`http://15.204.58.171:6004/user/authenticate`, { email, password })
        .then(user => {
            // publish user to subscribers and start timer to refresh token
            if(!util.getParameterByName('redirect')){
                sessionStorage.setItem("userData",JSON.stringify(user.user));
                sessionStorage.setItem("jwtToken",user.user.token);
            }

            userSubject.next(user);
            //startRefreshTokenTimer();
            return user;
        });
}

function logout() {
    const baseUrl = config && config.AUTH_API_SERVER;
    // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
    fetchWrapper.post(`${baseUrl}/revoke-token`, {});
    //stopRefreshTokenTimer();
    userSubject.next(null);
    history.push('/account/login');
}

 function refreshToken() {
     const baseUrl = config && config.AUTH_API_SERVER;
    return fetchWrapper.post(`${baseUrl}/user/refresh-token`, {})
    // return  fetchWrapper.post(`http://15.204.58.171:6004/user/refresh-token`, {})
        .then(user => {

            if(util.getParameterByName('redirect')){
                sessionStorage.removeItem("userData");
                sessionStorage.removeItem('jwtDLAuth');
                sessionStorage.removeItem("jwtToken");
            }

            // publish user to subscribers and start timer to refresh token
            let parmTokem =util.getParameterByName("token");
            // let token =sessionStorage.getItem("jwtToken");
            let usr = user.user;
            usr.token=sessionStorage.getItem('jwtDLAuth')?sessionStorage.getItem('jwtDLAuth'):parmTokem;
            usr.email= user.email?user.email:'';
                     usr.title= user.title? user.title:'';
                     usr.firstName= usr.name;
                     usr.lastName="";
                   // lastName: user.lastName,
                    //role: user.role,
                    userSubject.next({user:usr});

            if(!util.getParameterByName('redirect')){

                sessionStorage.setItem("userData",JSON.stringify(usr));
                usr.role=='SUPER-ADMIN'?history.push('/admin/users'):history.push('/profile/view/'+usr.sub);
            }

            
            //startRefreshTokenTimer();
           // console.log(history.location);  
            
          //return usr;
        });
}

function register(params) {
    const baseUrl = config && config.AUTH_API_SERVER;
    return fetchWrapper.post(`${baseUrl}/user/register`, params)
    // return fetchWrapper.post(`http://15.204.58.171:6004/user/register`, params)
    .then(user => {
             userSubject.next(user);
            //startRefreshTokenTimer();
            return user;
        });;
}

function verifyEmail(token) {
    const baseUrl = config && config.AUTH_API_SERVER;
    return fetchWrapper.post(`${baseUrl}/verify-email`, { token });
}

function forgotPassword(email) {
    const baseUrl = config && config.AUTH_API_SERVER;
    return fetchWrapper.post(`${baseUrl}/user/forgot-password`, { email });
}

function validateResetToken(token) {
    const baseUrl = config && config.AUTH_API_SERVER;
    return fetchWrapper.post(`${baseUrl}/user/validate-reset-token`, { token });
}

function resetPassword({ token, password, confirmPassword, user }) {
    const baseUrl = config && config.AUTH_API_SERVER;
    return fetchWrapper.post(`${baseUrl}/user/reset-password`, { token, password, confirmPassword, user });
}

function getAll() {
    const baseUrl = config && config.AUTH_API_SERVER;
    return fetchWrapper.get(baseUrl+'/user/admin/super');
}

function getById(id) {
    const baseUrl = config && config.AUTH_API_SERVER;
    return fetchWrapper.get(`${baseUrl}/user/${id}`);
}

function create(params) {
    const baseUrl = config && config.AUTH_API_SERVER;
    return fetchWrapper.post(`${baseUrl}/user/register`, params);
}

function update(id, params) {
    const baseUrl = config && config.AUTH_API_SERVER;
    return fetchWrapper.put(`${baseUrl}/user/${id}`, params)
        .then(user => {
            // update stored user if the logged in user updated their own record
            if (user.id === userSubject.value.id) {
                // publish updated user to subscribers
                user = { ...userSubject.value, ...user };
                userSubject.next(user);
                fetchWrapper.get(`${baseUrl}/user/${id}`);
            }
            return user;
        });
}
function passwrd(id, params) {
    const baseUrl = config && config.AUTH_API_SERVER;
    return fetchWrapper.put(`${baseUrl}/user/update-pass/${id}`, params)
        .then(user => {
            if (user.id === userSubject.value.id) {
                user = { ...userSubject.value, ...user };
                userSubject.next(user);
            }
            return user;
        });
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
    const baseUrl = config && config.AUTH_API_SERVER;
    return fetchWrapper.delete(`${baseUrl}/user/${id}`)
        .then(x => {
            // auto logout if the logged in user deleted their own record
            if (id === userSubject.value.id) {
                logout();
            }
            return x;
        });
}

// helper functions

let refreshTokenTimeout;

function startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(userSubject.value.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    refreshTokenTimeout = setTimeout(refreshToken, timeout);
}

function stopRefreshTokenTimer() {
    clearTimeout(refreshTokenTimeout);
}
