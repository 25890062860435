import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { accountService } from "./../_services";
import { Login } from "./Login";
import { Register } from "./Register";
import { VerifyEmail } from "./VerifyEmail";
import { ForgotPassword } from "./ForgotPassword";
import { ResetPassword } from "./ResetPassword";
import { util, config } from "../_helpers";

function Account({ history, match }) {
  const { path } = match;
  const { search } = useLocation();

  console.log('search' + search)

  useEffect(() => {
    // redirect to home if already logged in
    // if (accountService.userValue) {
    //   history.push({ pathname: `/${search}` });
    // }
  }, []);

  return (
    <div>
      <div className="row  bg-secondary-subtle " style={{height:"100vh"}}>
        <div className="col-sm-12 col-lg-4   d-flex " style={{flexDirection:"column"}}>
<div id="logo" style={{ textAlign: "center", marginTop: "20px" }}>
            <img src={config.Black_Theme_Logo} width="250px" height="auto" style={{marginBottom:"20px"}} />
          </div>
          <Switch>

            <Route path={`${path}/login`} component={Login} />
            <Route path={`${path}/register`} component={Register} />
            <Route path={`${path}/verify-email`} component={VerifyEmail} />
            <Route
              path={`${path}/forgot-password`}
              component={ForgotPassword}
            />
            <Route path={`${path}/reset-password`} component={ResetPassword} />
          </Switch>

        </div>
        <div className="col-sm-12 col-lg-8" id="promo-section">
         
          <div id="content-slider">
            <div id="slider">
              <div id="mask">
                <ul>
                  <li id="first" className="firstanimation"></li>
                  <li id="second" className="secondanimation"></li>
                  <li id="third" className="thirdanimation"></li>
                  <li id="fourth" className="fourthanimation"></li>
                  <li id="fifth" className="fifthanimation"></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Account };
