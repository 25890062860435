// import util from "./util";

let defaultConf = {
    AUTH_API_SERVER: 'https://authapi.digitallive24.com',
    PAY_SERVER: 'https://pay.digitallive24.com',
    Black_Theme_Logo:'https://cms.digitallive24.com/assets/logo-dark-large-menu.png',
    Google_Client_Id:"1079254330547-n7cfcuog5clacms13oncaldiit7td432.apps.googleusercontent.com",
    Provider:"DIGITAL LIVE 24"
};
// export default config;


//import axios from "axios";


// let domain = window.location.href.indexOf('localhost') > 0 ?'https://authapi.digitallive24.com':window.location.origin;

//  export  function getParameterByName(e,t=window.location.href){
//     e=e.replace(/[\[\]]/g,"\\$&");var n=new RegExp("[?&]"+e+"(=([^&#]*)|&|#|$)").exec(t);
//   return n?n[2]?decodeURIComponent(n[2].replace(/\+/g," ")):"":null;
//   };

// const config = new Promise((response, rej) => {
//   let savedconfig =localStorage.getItem("configs") ?JSON.parse(localStorage.getItem("configs")):null;
//     if(!savedconfig){
//    axios.get(`https://cms.${domain.split(".")[1]}.${domain.split(".")[2]}/api/configs`)
//   .then(res=>{
//         console.log(res.data[0]);
//         let data = res&&res.data.length?JSON.stringify(res.data[0]):null;
//         localStorage.setItem("configs",data);
//         savedconfig = JSON.parse(data);
//         response(savedconfig);
//     });
// }else{
//     response(savedconfig);
// }
// });

const savedconfig =localStorage.getItem("configs") ?JSON.parse(localStorage.getItem("configs")):defaultConf;
//getConfigs().then((res)=> console.log(res));

export default savedconfig;
